import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import { assignUserAlgs, assignUserDevices, getUserSpecificAlgs, getUserSpecificDevices } from '../../../actions/internalActions';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

export default function UserConfigModel(props) {
  const { userId, email, open, handleClose } = props;
  const [allDevices, setAllDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [allAlgs, setAllAlgs] = useState([]);
  const [selectedAlgs, setSelectedAlgs] = useState([]);
  const allDeviceData = useSelector((state) => state.internal.devices);
  const allAlgData = useSelector((state) => state.internal.algs);


  useEffect(() => {
    if (userId && allDeviceData) {
      (async () => {
        const userDevices = await getUserSpecificDevices(userId);
        setAllDevices(allDeviceData.map((device) => device.device_id.toString()));
        setSelectedDevices(userDevices.map((device) => device.device_id.toString()));
      })();
    }
  }, [userId, allDeviceData]);

  useEffect(() => {
    if (userId && allAlgData) {
      (async () => {
        const userAlgs = await getUserSpecificAlgs(userId);
        setAllAlgs(allAlgData.map((alg) => alg.alg_id.toString()));
        setSelectedAlgs(userAlgs.map((alg) => alg.alg_id.toString()));
      })();
    }
  }, [userId, allAlgData]);


  const handleSave = async () => {
    console.log(selectedAlgs);
    console.log(selectedDevices);
    console.log(userId);
    await assignUserAlgs(userId, selectedAlgs);
    await assignUserDevices(userId, selectedDevices);
    handleClose();
  };

  const handleChangeDevice = (event, value) => {
    setSelectedDevices(value);
  };

  const handleChangeAlgs = (event, value) => {
    setSelectedAlgs(value);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>User: {email}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete 
              multiple
              onChange={handleChangeDevice}
              value={selectedDevices}
              options={allDevices}
              renderInput={(params) => <TextField {...params} label="Device IDs" />}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete 
              multiple
              onChange={handleChangeAlgs}
              value={selectedAlgs}
              options={allAlgs}
              renderInput={(params) => <TextField {...params} label="Alg IDs" />}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
