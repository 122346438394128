import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { createTheme } from '@mui/material';

import { useSelector } from 'react-redux';
import CustomTableContainer from '../Common/Table/CustomTableContainer';
import { HeaderDataTypes } from '../../util/constants';
import { getDisplayName } from '../../util/deviceHelpers';
import { getFormattedTimeString } from '../../util/time';
import DeviceClearModal from './modals/deviceClear';

const theme_bp = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 680,
      md: 850,
      lg: 1200,
      xl: 1536,
    },
  },
});

const useStyles = makeStyles({

});

export default function AllDevices(props) {
  const [processedDevices, setProcessedDevices] = useState([]);
  const devices = useSelector((state) => state.internal.devices);

  const [modalData, setModalData] = useState({ open: false});

  const handleClick = (event, row) => {
    const newModalData = {
      open: true,
      deviceId: row.device_id,
    };
    setModalData(newModalData);
  }

  const handleClose = () => {
    setModalData({ open: false });
  }

  const defaultHeaders = [
    {
      id: 'device_id',
      type: HeaderDataTypes.NUMBER,
      label: 'Device ID',
      display: true
    },
    {
      id: 'display_name',
      type: HeaderDataTypes.STRING,
      label: 'Device Name',
      display: false
    },
    {
      id: 'on_device_name',
      type: HeaderDataTypes.STRING,
      label: 'On Device Name',
      display: true,
      info: "Name associated with the device locally. Used to ssh into the device."
    },
    {
      id: 'last_seen',
      type: HeaderDataTypes.DATE,
      label: 'Last Seen (UTC)',
      display: true
    },
    {
      id: 'lat',
      type: HeaderDataTypes.NUMBER,
      label: 'Latitude',
      display: false
    },
    {
      id: 'long',
      type: HeaderDataTypes.NUMBER,
      label: 'Longitude',
      display: false
    },
    {
      id: 'cycle_time',
      type: HeaderDataTypes.NUMBER,
      label: 'Cycle Time',
      display: false,
      info: "Maximum time between wake up cycles."
    },
    {
      id: 'trigger_number',
      type: HeaderDataTypes.NUMBER,
      label: 'Trigger Number',
      display: false,
      info: "Number of backlog images before device wakes up."
    },
    {
      id: 'memory_remaining',
      type: HeaderDataTypes.NUMBER,
      label: 'Memory Remaining (MB)',
      display: false
    },
    {
      id: 'cycle_count',
      type: HeaderDataTypes.NUMBER,
      label: 'Cycle Count',
      display: false
    },
    {
      id: 'images_processed',
      type: HeaderDataTypes.NUMBER,
      label: 'Images Processed',
      display: false
    },
    {
      id: 'sat_passes',
      type: HeaderDataTypes.STRING,
      label: 'Sat Passes',
      display: false
    },
    {
      id: 'wifi_images',
      type: HeaderDataTypes.STRING,
      label: 'Allow Wifi Images',
      display: false
    },
    {
      id: 'wifi_insights',
      type: HeaderDataTypes.STRING,
      label: 'Allow Wifi Insights',
      display: false
    },
    {
      id: 'swarm_id',
      type: HeaderDataTypes.STRING,
      label: 'Swarm ID',
      display: true
    },
    {
      id: 'lora_id',
      type: HeaderDataTypes.STRING,
      label: 'Lora ID',
      display: false
    },
    {
      id: 'code_version',
      type: HeaderDataTypes.STRING,
      label: 'Code Version',
      display: false
    },
    {
      id: 'mac_id',
      type: HeaderDataTypes.STRING,
      label: 'Mac ID',
      display: true
    },
    {
      id: 'alg_ids',
      type: HeaderDataTypes.STRING,
      label: 'Alg Ids',
      display: true
    },
  ];

  useEffect(() => {
    if (devices) {
      setProcessedDevices(
        devices.map((device, index) => {
          return {
            id: index,
            device_id: device.device_id,
            display_name: getDisplayName(device),
            on_device_name: device.device_name,
            last_seen: getFormattedTimeString(device.last_seen),
            lat: device.latitude,
            long: device.longitude,
            cycle_time: device.cycle_time,
            trigger_number: device.trigger_number,
            sat_passes: device.sat_passes,
            wifi_images: device.allow_complete_images === 1,
            wifi_insights: device.allow_wifi_insights === 1,
            swarm_id: device.satellite_uid,
            lora_id: device.lora_uid,
            code_version: device.software_version,
            mac_id: device.hardware_id,
            memory_remaining: device.memory_remaining || device?.summary?.space_remaining || null,
            images_processed: device.images_processed || device?.summary?.image_count || null,
            cycle_count: device?.summary?.cycle_count || null,
            alg_ids: device?.alg_ids?.join(",")
          }
        })
      );
    }
    
  }, [devices]);

  return (
    <>
      <CustomTableContainer
        defaultOrder="desc"
        defaultOrderHeader="device_id"
        headers={defaultHeaders}
        rowData={processedDevices}
        paginationOptions={[5, 10, 25, 50, 100]}
        defaultPaginationOption={25}
        clickAction={handleClick}
      /> 
      {
      modalData && modalData.deviceId && 
      (<DeviceClearModal
        deviceId={modalData.deviceId}
        open={modalData.open}
        handleClose={handleClose}
      />)
}
    </>
  );
}
