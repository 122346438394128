import { combineReducers } from 'redux';
import userReducer from './userReducer';
import deviceReducer from './deviceReducer';
import groupReducer from './groupReducer';
import userInsightsReducer from './userInsightsReducer';
import modelMarketplaceReducer from './modelMarketplaceReducer';
import userInfoSqlReducer from './userInfoSqlReducer';
import modelReducer from './modelReducer';
import integrationReducer from './integrationReducer';
import featuresReducer from './featuresReducer';
import internalReducer from './internalReducer';

const allReducers = combineReducers({
  user: userReducer,
  device: deviceReducer,
  feature: featuresReducer,
  group: groupReducer,
  userInsights: userInsightsReducer,
  marketplace: modelMarketplaceReducer,
  userInfoSql: userInfoSqlReducer,
  model: modelReducer,
  userIntegrations: integrationReducer,
  internal: internalReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return allReducers(undefined, action)
  }

  return allReducers(state, action)
}

export default rootReducer;
