import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CreateIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import { CYCLE_STEPS, SYNC_STEPS } from '../../util/constants';

import { updateDevice, updateDeviceDisplay } from '../../actions/deviceActions';
import { getDisplayName } from '../../util/deviceHelpers';

export default function EditDeviceModal(props) {
  const { device, open, handleClose } = props;
  const allDeviceData = useSelector((state) => state.device.deviceData);


  const [deviceState, setDeviceState] = useState(allDeviceData && allDeviceData.find((d) => d.device_id === device));

  useEffect(() => {
    setDeviceState(allDeviceData.find((d) => d.device_id === device));
  }, [allDeviceData]);

  const dispatch = useDispatch();

  const handleSave = () => {
    dispatch(updateDevice({...deviceState}));
    dispatch(updateDeviceDisplay({...deviceState}));
    handleClose();
  };

  const handleChange = (key, value) => {
    setDeviceState({
      ...deviceState,
      [key]: value,
    });
  };

  const handleLocationChange = (key, value, min, max) => {
    const parsedValue = parseFloat(value);
    if (!parsedValue || (parsedValue >= min && parsedValue <= max)) {
      handleChange(key, value);
    }
  }

  // Build the string for converting number of minutes to either 'MM minutes' or 'HH hours'.
  const cycleFreqUnits = deviceState.cycle_time >= 60 ? 'Hour' : 'Minute';
  const cycleFreqValue =
    deviceState.cycle_time >= 60
      ? deviceState.cycle_time / 60
      : deviceState.cycle_time;
  const cycleFrequency = `${cycleFreqValue} ${cycleFreqUnits}${
    cycleFreqValue !== 1 ? 's' : ''
  }`;

  // Build the string for converting number of minutes to either 'MM minutes' or 'HH hours'.
  const syncFreqUnits = deviceState.sync_time >= 60 ? 'Hour' : 'Minute';
  const syncFreqValue =
    deviceState.sync_time >= 60
      ? deviceState.sync_time / 60
      : deviceState.sync_time;
  const syncFrequency = `${syncFreqValue} ${syncFreqUnits}${
    syncFreqValue !== 1 ? 's' : ''
  }`;

  //  I just want to make this comment here for changes SVGMaskElement

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Editing Device: {getDisplayName(deviceState)}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="dense"
              label="Device Name"
              name="device_name"
              inputProps={{
                maxLength: 30
              }}
              value={deviceState.display_name}
              onChange={(event) => handleChange("display_name", event.currentTarget.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              variant="outlined"
              rows={4}
              inputProps={{
                maxLength: 100
              }}
              margin="dense"
              label="Device Description"
              name="description"
              value={deviceState.description}
              onChange={(event) => handleChange("description", event.currentTarget.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="dense"
              label="Location Name"
              name="location_name"
              inputProps={{
                maxLength: 30
              }}
              value={deviceState.location_name}
              onChange={(event) => handleChange("location_name", event.currentTarget.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              variant="outlined"
              rows={4}
              inputProps={{
                maxLength: 100
              }}
              margin="dense"
              label="Location Description"
              name="location_description"
              value={deviceState.location_description}
              onChange={(event) => handleChange("location_description", event.currentTarget.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              type="number"
              inputProps={{
                maxLength: 30
              }}
              margin="dense"
              label="Latitude"
              name="latitude"
              value={deviceState.latitude}
              onChange={(event) => handleLocationChange("latitude", event.currentTarget.value, -90, 90)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              type="number"
              inputProps={{
                maxLength: 30
              }}
              margin="dense"
              label="Longitude"
              name="longitude"
              value={deviceState.longitude}
              onChange={(event) => handleLocationChange("longitude", event.currentTarget.value, -180, 180)}
            />
          </Grid>

          <Grid item xs={12}>
            <InputLabel>
              Wake up the device every {cycleFrequency}{' '}
            </InputLabel>
            <Slider
              value={deviceState.cycle_time}
              marks={CYCLE_STEPS}
              step={null}
              max={2880}
              onChange={(_, value) => handleChange("cycle_time", value)}
            />
          </Grid>

          <Grid item xs={12}>
            <InputLabel>
              Check & Process Data Every {syncFrequency}{' '}
            </InputLabel>
            <Slider
              max={2880}
              value={deviceState.sync_time}
              marks={SYNC_STEPS}
              step={null}
              onChange={(_, value) => handleChange("sync_time", value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
